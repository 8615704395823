<template>
  <div>
    <!-- <Modal
      :open="open1"
      :title="$t('kkrbjlp.subscribeNumber')"
      :description="$t('kkrbjlp.subscribeTest')"
      image="landingPage/assets/Frames.svg"
      @change="reset1"
    /> -->

    <ClosableModal
      :open="open1"
      
      :title="$t('kkrbjlp.subscribeNumber')"
      :desc="$t('kkrbjlp.subscribeTest')"
      @close="reset1"
    >
      <template v-slot:content>
        <div class="overflow-auto">
          <img
            src="landingPage/assets/facture_sbee.webp"
            style="max-height: 55vh"
            class="w-full img-web"
           
          />

          <div>
            <img
              src="landingPage/assets/Frames.svg"
              style="max-height: 55vh"
              class="w-full img-mobile"
           
            />
          </div>

          <div class="flex justify-center my-2 mt-2">
            <button
              class="bg-tex-2 py-3 px-6 rounded text-white"
              @click="open1 = false"
            >
              {{ $t("kkrbjlp.finished") }}
            </button>
          </div>
        </div>
      </template>
    </ClosableModal>
    <ClosableModal
      :open="open2"
      :title="$t('kkrbjlp.meterTest')"
      :desc="$t('kkrbjlp.meterDescription')"
      @close="reset2"
    >
      <template v-slot:content>
        <div class="overflow-auto">
          <img
            src="landingPage/assets/Rectangle.webp"
            style="max-height: 55vh"
            class="w-full img-web"
           
          />

          <div>
            <img
              src="landingPage/assets/Rectangle.webp"
              style="max-height: 55vh"
              class="w-full img-mobile"
             
            />
          </div>

          <div class="flex justify-center my-2 mt-2">
            <button
              class="bg-tex-2 py-3 px-6 rounded text-white"
              @click="open2 = false"
            >
              {{ $t("kkrbjlp.finished") }}
            </button>
          </div>
        </div>
      </template>
    </ClosableModal>
    <Modal
      :open="open2"
      :title="$t('kkrbjlp.meterTest')"
      :description="$t('kkrbjlp.meterDescription')"
      image="landingPage/assets/Rectangle.png"
      @change="reset2"
    />
    <div class="p-6">
      <div class="">
        <div>
          <div>
            <div class="">
              <form action="#" @submit.prevent="">
                <div class="">
                  <div class="flex flex-col clg:w-large csm:w-input">
                    <div
                      class="
                        flex
                        justify-between
                        border
                        items-center
                        rounded-full
                        px-4
                        py-2
                        border-1.5 border-kborder-500
                      "
                    >
                      <input
                        :placeholder="$t('kkrbjlp.placeholder2')"
                        v-model="counterNum"
                        class="
                          uppercase
                          hover:border-kinput-3
                          focus:border-kinput-2
                          flex-grow
                          leading-tight
                          focus:outline-none focus:bg-white
                        "
                        v-validate="'required'"
                        id="compId"
                        name="compId"
                        @input="resetValidation"
                        type="text"
                        min="0"
                        maxlength="20"
                        :class="{
                          ' border-tex-2': submitted && errors.has('compId'),
                        }"
                      />

                      <div
                        class="tooltip"
                        ref="toolTipRef"
                        style="font-weight: 600px; color: #2d7bf7"
                      >
                        <a
                          @click="showTooltip"
                          class="cursor-pointer clg:flex csm:hidden"
                          style="font-weight: 600"
                          >{{ $t("kkrbjlp.needHelp") }}</a
                        >
                        <div @click="showTooltip">
                          <Question class="csm:flex clg:hidden" />
                        </div>
                        <div
                          :class="
                            visible ? 'tooltiptext showTooltip' : 'tooltiptext'
                          "
                        >
                          <a
                            @click="toggleModal1"
                            class="
                              flex
                              justify-between
                              p-2
                              cursor-pointer
                              b
                              items-center
                              borderDotted
                            "
                          >
                            <span> {{ $t("kkrbjlp.subNumber") }}</span>
                            <Right />
                          </a>
                          <a
                            @click="toggleModal2"
                            class="
                              flex
                              justify-between
                              p-2
                              cursor-pointer
                              items-center
                            "
                          >
                            <span> {{ $t("kkrbjlp.moreKnow") }}</span>
                            <Right />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p
                      v-show="submitted && errors.has('compId')"
                      class="text-tex-2"
                    >
                      {{ $t("counterRecharge.validateLand2") }}
                    </p>
                    <p v-show="invalidMeter" class="text-tex-2">
                      {{ $t("counterRecharge.invalidMeterLand2") }}
                    </p>
                  </div>
                  <div class="flex justify-center mt-4 space-x-2 -4 p-2">
                    <div>
                      <button
                        type="submit"
                        @click="passCountPay"
                        class="
                          resButton
                          focus:outline-none
                          bg-kbutton-4
                          px-10
                          p-2
                          rounded
                          font-semibold
                        "
                      >
                        <div class="loaderDiv flex justify-center" v-if="load1">
                          <div class="loader"></div>
                        </div>
                        <span v-else>
                          {{ $t("kkrbjlp.firstButton") }}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClosableModal from "components/landingPage/modal";
import Right from "components/landingPage/rightWhite.vue";
import Question from "components/landingPage/questionMark.vue";
import { billRechargeApi } from "@/main";
import axios from "axios";
export default {
  components: {
    Right,
    Question,
    ClosableModal,
    //  IconCompt2,
    // BaseButton,
  },
  data() {
    return {
      imageLoaded: false,
      imageLoaded2: false,
      open1: false,
      open2: false,
      loading: false,
      load1: false,
      load2: false,
      submitted: false,
      invalidMeter: false,
      blockedMeter: false,
      hasNotEnougthMoney: false,
      counterNum: "",
      visible: false,
      lang: "fr",
    };
  },
  props: {
    title: String,
    link: String,
    description: String,
    image: String,
  },

  methods: {
    reset1() {
      this.open1 = false;
    },
    toggleModal1() {
      this.open1 = true;
    },
    reset2() {
      this.open2 = false;
    },
    toggleModal2() {
      this.open2 = true;
    },
    async submit() {
      console.log("toto");
    },
    showTooltip() {
      console.log("ggg");
      this.visible = !this.visible;
      console.log(this.visible);
    },

    passCountPay() {
      // To upper case
      this.counterNum = String(this.counterNum).toUpperCase();

      this.lang = this.lang.substr(0, 2);
      if (this.lang != "fr") {
        this.lang = "en";
      }
      this.submitted = true;

      
        if (/^\d{12}[A-Z]{2}$/g.test(this.counterNum)) {
          this.load1 = true;

          this.$validator.validate().then((valid) => {
            if (valid) {
              this.loading = true;

              billRechargeApi
                .get(
                  `/subscribers?reference=${this.counterNum}&local=${this.lang}`
                )
                .then(async (res) => {
                  const data = res.data;
                  if (data.status) {
                    data.status == 422 ? (this.invalidMeter = true) : "";
                    this.load1 = false;
                  } else if (this.counterNum) {
                    console.log(this.$store);
                    this.load1 = true;
                    this.$store.commit("mutBillProcessFirstPur", data);
                    await this.$store.commit("mutBillProcessBoolean", true);
                    await this.$store.commit("mutPaymentStep", true);
                    console.log(this.$route.path);
                    //redirect to /recharges ?
                    if (this.$route.path === "/land")
                      this.$router.push(`/recharges?ref=${this.counterNum}&local=${this.lang}`)
                  }
                })
                .catch((err) => {
                  if (err.response) {
                    const data = err.response.data;
                    console.log("errdata: ", data);
                    this.load1 = false;
                  }
                })
                .finally(() => {
                  this.loading = false;
                  this.load1 = false;
                });
            }
          });
        } else {
          this.load1 = true;
          console.log("1");
          this.$validator.validate().then((valid) => {
            if (valid) {
              this.loading = true;
              console.log("12");

              axios
                .post("/transaction", {
                  meterNumb: this.counterNum,
                  amount: "1",
                  userId: this.$store.getters.getAuthUser.id,
                })
                .then(async (res) => {
                  console.log("Where Am I ???");
                  const data = res.data;
                  const debt =
                    parseFloat(data.arrearAMT) + parseFloat(data.feeAMT);
                  const meter = {
                    number: data.meterNum,
                    user: data.customerName,
                    debt: debt,
                    transacId: data.transID,
                  };
                  this.$store.commit("mutCounterFirstPur", meter);
                  await this.$store.commit("mutPaymentStep", true);
                  await this.$store.commit("mutBillProcessBoolean", false);
                  //redirect to /recharges ?
                  if (this.$route.path === "/land")
                    this.$router.push("recharges");
                  //
                  if (this.hasNotEnougthMoney) {
                    this.hasNotEnougthMoney = false;
                  }
                })
                .catch((err) => {
                  console.log("I am were ??? Error");
                  // const message = err.response.data.message;
                  if (err.response) {
                    // console.log('error response --->', err.response);
                    const data = err.response.data;

                    //   console.log('data: ', data);
                    if (data.state == "-10006") {
                      // console.log('Invalid ====>');
                      this.invalidMeter = true;
                      this.load1 = false;
                    } else if (data.locked == "meter blocked") {
                      // console.log('blockedMeter ====>');
                      this.blockedMeter = true;
                    } else if (data.state == "-10023") {
                      this.hasNotEnougthMoney = true;
                    }
                  }
                })
                .finally(() => {
                  this.loading = false;
                });
            }
          });
        }
    },
    resetValidation() {
      if (this.invalidMeter) {
        this.invalidMeter = false;
      }
      if (this.blockedMeter) {
        this.blockedMeter = false;
      }
    },

    showFindReload() {
      this.$store.commit("mutFindReloadStep", true);
    },
    showLoader1() {
      this.load1 = true;
    },
    showLoader2() {
      this.load2 = true;
    },
  },
  mounted() {
    this.lang = navigator.language;
    console.log("lang", this.lang.substr(0, 2));
    document.addEventListener("click", (event) => {
      const tooltipRef = this.$refs.toolTipRef;
      if (tooltipRef && !tooltipRef.contains(event.target)) {
        this.visible = false;
      }
    });

    this.loading = false;
    this.counterNum = this.$store.getters.getPrevCounterNumber;
  },
};
</script>

<style>
.tooltip {
  position: relative;
  display: inline-block;
}

.borderDotted {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
}
.resButton {
  font-size: 16px;
}
.img-mobile {
  display: none;
}

.img-web {
  display: block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 312px;
  transform: translateX(-165px);
  background-color: #41464c;
  color: #fff;
  font-size: 14px;
  /* text-align: center; */
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: 115%;
  left: 50%;
  /* margin-right: -50px; */
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;

  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #41464c transparent;
}

.showTooltip {
  visibility: visible !important;
}
@media (max-width: 767px) {
  .img-web {
    display: none;
  }

  .img-mobile {
    display: block;
  }
  .resButton {
    font-size: 12px;
  }
  .tooltip .tooltiptext {
    transform: translateX(-298px);
    margin-top: 28%;
  }
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 95%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #41464c transparent;
  }
}
.loaderDiv {
  width: 270px;
}
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid black;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

::placeholder {
  text-transform: capitalize;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>