<template>

<div class="w-full bg-bgk-8 ill" >
        <div class="max-w-lpw csm:w-11/12 clg:pt-3 clg:w-83p mx-auto clg:bg-illustration clg:bg-ill-back-size clg:bg-no-repeat clg:bg-ill-left-bott">
          <div class="p-10 csm:p-6 flex flex-col items-center justify-center">
            <p class="text-bgk-4 clg:text-28fl clg:leading-40fl text-base font-semibold text-center mb-3 clg:w-10/12 m-0 mx-auto">
              <slot></slot>
            </p>
            <div class="mx-1 flex items-center">
              
              <router-link to="/recharges/register">
               <hButton  :text="$t('sign.register')"/>
              </router-link>
              <router-link to="/recharges/signsuccess">
               <lButton  class="ml-4" :text="$t('sign.login')" />
              </router-link>
            </div>
          </div>
        </div>
</div>

</template>

<script>

import hButton from 'components/landingPage/hButton.vue'
import lButton from 'components/landingPage/lButton.vue'

export default {
    components: {
    hButton,
    lButton
  },
}

</script>

<style scoped>

     .ill{
  z-index: 1000px !important;
  position: inherit;


}
</style>
