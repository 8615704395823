<template>
  <button v-html="rendered" class="flex items-center justify-center truncate font-semibold border border-tex-1 text-bgk-4 border-bgk-4 clg:w-166fl csm:w-120fl clg:h-42fl csm:h-8 hover:border-tex-6 hover:text-tex-6 active:border-tex-6 py-3 px-6 csm:px-2 csm:py-4 csm:text-sm csm:leading-16l clg:text-base clg:leading-18l rounded focus:outline-none">
  </button>
</template>

<script>

export default {
    data() {
    return {
      rendered: ''
    }
    },

    props:{
      text: {
      default: '',
      type: String
     },
    },

  created() {
    this.render()
  },
  methods: {
    render() {
      this.rendered = this.text;
    }
  }


}
</script>

<style scoped>

</style>
