<template>
  <div>
    <div
      class="
        fixed
        top-0
        d-web
        left-0
        w-full
        h-full
        overflow-hidden
        modal-container
        first
        flex
        justify-center
        items-center
      "
      ref="modalRef"
      v-if="isOpened "
    >
      <div class="modal-web  border b-radius bg-white p-6 p-0 clg:p-8">
        <div class="flex justify-between items-end">
          <h2 class="font-bold" style="font-size: 24px">{{ title }}</h2>
          <div @click="close()" class="cursor-pointer"><CloseLogo /></div>
        </div>
        <p class="font-semibold py-6">{{ desc }}</p>
        <slot name="content" />
      </div>
    </div>

    <div :class="mobileCss" ref="modalRef" v-if="isOpened">
      <div class="border b-radius bg-white p-3 modal-content">
        <div class="flex justify-between items-end">
          <h2 class="font-bold" style="font-size: 24px">{{ title }}</h2>
          <div @click="close()" class="cursor-pointer"><CloseLogo /></div>
        </div>
        <p class="font-semibold py-6">{{ desc }}</p>
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
import CloseLogo from '../../assets/svg/dashbIcons/close.svg?inline'
export default {
  props: ["title", "desc", "open", "loaded"],
  components:{
    CloseLogo
  },
  data() {
    return {
      isOpened: this.open,
      mobileCss: "d-mobile modal-container",
    };
  },
  watch: {
    open: function (value) {
      if (!value) {
        this.close();
      } else {
        this.mobileCss = "d-mobile modal-container";
        this.isOpened = value;
        window.addEventListener("scroll", this.scrollListener);
      }
    },
  },
  methods: {
    close() {
      this.mobileCss = "d-mobile modal-container close";
      setTimeout(() => {
        this.isOpened = false;
        window.removeEventListener("scroll", this.scrollListener);
        this.$emit("close");
      }, 290);
    },
    scrollListener() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.first {
  z-index: 999;
}

.b-radius {
  border-radius: 16px 16px 0px 0px;
}

.modal-container {
  position: fixed;
 
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 75%; */
  background-color: #fff;
  overflow: hidden;
  animation: 0.3s open ease-in-out;
}

.close {
  animation: 0.3s close ease-in-out;
}

.d-mobile {
  display: none;
}

.d-web {
  display: flex;
}

.modal-web {
  animation: fadeIn 0.5s linear forwards;
}

@media (max-width: 600px) {
  .d-mobile {
    display: block;
  }

  .d-web {
    display: none;
  }
}

@keyframes open {
  from {
    max-height: 0;
  }
  to {
    max-height: 100%;
  }
}

@keyframes close {
  from {
    max-height: 100%;
  }
  to {
    max-height: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>