<template>
  <div>
    <header>
      <KheadWithoutLogo />
    </header>

    <main
      class=" main h-full flex flex-row justify-center items-center"
      
    >
      <div>
        <div class="flex flex-col justify-center">
          <img
            src="landingPage/assets/logo.svg"
            alt=""
            style="height: 69.08px"
          />
        </div>
        <div>
          <InputComponent />
          <div></div>
        </div>
      </div>
    </main>

    <Illustration>
      <span style="font-weight: 600; font-size: 24px; line-height: 40px">{{
        $t("kkrbjlp.moreThan20")
      }}</span>
    </Illustration>
  </div>
</template>

<script>
import InputComponent from "components/landingPage/transparentButton";
import Illustration from "components/landingPage/illustration.vue";
import KheadWithoutLogo from "components/landingPage/partials/KheadWithoutLogo.vue";

export default {
  components: {
    KheadWithoutLogo,
    InputComponent,
    Illustration,
  },
  data() {
    return {
      signMenu: false,
    };
  },
  computed: {
    paymentStep() {
      return this.$store.getters.getPaymentStep;
    },
    pleaseWaitStep() {
      return this.$store.getters.getPleaseWaitStep;
    },
    findReloadStep() {
      return this.$store.getters.getFindReloadStep;
    },
  },
  mounted() {
    this.$store.commit("mutPaymentStep", false);
    this.$store.commit("mutPleaseWaitStep", false);
    this.$store.commit("mutFindReloadStep", false);
  },
  methods: {},
};
</script>

<style scoped>
.bg-frame-916 {
  width: 117.5px;
  height: 113.75px;
  top: -2.5rem;
  right: -2.281rem;
}
.main{
    height: 80vh;
  }
.sbeeicon {
  width: 2.477rem;
  height: 2.477rem;
}

@media (max-width: 767px) {
  .sbeeicon {
    width: 1.773rem;
    height: 1.773rem;
  }
  .main{
    height: 40vh;
  }
}
</style>
