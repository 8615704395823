<template>
  <div class="w-full bg-white">
    <div class=" clg:hidden csm:flex max-w-lpw mx-auto">
      <div
        class="
          bg-white
          flex
      justify-center
          px-0
          py-4
          mx-auto
          
          clg:w-83p
        "
      >
      <router-link to="/recharges/signsuccess">
            <lButton :text="$t('sign.login')" />
          </router-link>
          <router-link to="/recharges/register">
            <hButton class="ml-4 csm:ml-2" :text="$t('sign.register')" />
          </router-link>
      
      </div>
    </div>
    <div class="max-w-lpw mx-auto">
      <div
        class=" 
        clg:flex csm:hidden
          bg-white
          flex
          justify-end
          px-0
          py-4
          mx-auto
          csm:w-11/12
          clg:w-83p
        "
      >
      <router-link to="/recharges/signsuccess">
            <lButton :text="$t('sign.login')" />
          </router-link>
          <router-link to="/recharges/register">
            <hButton class="ml-4 csm:ml-2" :text="$t('sign.register')" />
          </router-link>
      
      </div>
    </div>
  </div>
  
</template>

<script>
//import Iconkkiapay from "components/landingPage/Iconkkiapay.vue";
//import Iconkkiapaysm from "components/landingPage/Iconkkiapaysm.vue";
import hButton from "components/landingPage/hButton.vue";
import lButton from "components/landingPage/lButton.vue";

export default {
  components: {
    // Iconkkiapay,
    //Iconkkiapaysm,
    hButton,
    lButton,
  },

  data() {
    return {
      signMenu: false,
    };
  },
};
</script>

<style scoped>
</style>
